function findPrimaryVendor(location, subs) {
    if (!location){
        return ""
    }
    for (let sub of subs) {
        const index = location.fields['Primary vendors'].indexOf(sub.id)
        if (index !== -1){
            return sub
        }
    }
}

export default findPrimaryVendor;