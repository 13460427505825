import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Portapotty from './routers/portapotty';
import Dumpster from './routers/dumpster'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>

<BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="portapotty/request" element={<Portapotty action="request"/>} />
      <Route path="portapotty/remove" element={<Portapotty action="remove"/>} />
      <Route path="dumpster/request" element={<Dumpster action="request"/>} />
      <Route path="dumpster/remove" element={<Dumpster action="remove"/>} />
    </Routes>
  </BrowserRouter>,

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
