import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const dateField = (formik) => {
    
    return (
        <>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              renderInput={(props) => <TextField sx={{'width' : '100%'}} {...props} />}
              label={"Delivery Date"}
              value={formik.values.deliveryTime}
              onChange={(newValue) => {
                formik.setFieldValue(`deliveryTime`, newValue)
              }}
              minDate={new Date()}
            />
          </LocalizationProvider> 
        </Grid>
        </>
    )
}

export default dateField;