import { React, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import fetchAirtableData from '../helpers/airtable';
import { useFormik } from 'formik';
import SingleSelect from '../components/singleSelect';
import autocomplete from '../components/autoComplete';
import projectInfo from '../components/projectInfo';
import submit from '../helpers/submit'


function Portapotty(props) {
    let [project, setProject] = useState({});
    let [subs, setSubs] = useState([]);
    let [areas, setAreas] = useState([]);
    let [primaryVendor, setPrimaryVendor] = useState({ 'fields': { 'Full Name': "" } });
    let projectId = Object.fromEntries(new URLSearchParams(window.location.search).entries())['id'];

    const allReady = () => {
        return Object.keys(project).length !== 0 &&
            areas.length !== 0 &&
            subs.length !== 0
    }

    useEffect(() => {
        if (projectId) {
            fetchAirtableData(projectId, (result) => {
                setProject(result[0].data.fields)
                setSubs(result[1].data.records.filter(sub => sub.fields['Division and Trade'].indexOf('02-Portable restrooms') !== -1))
                setAreas(result[2].data.records)
            })
        } else {
            alert("No project ID: this link is not valid")
        }

    }, [projectId])

    const theme = createTheme({
        spacing: 8,
    });

    const formik = useFormik({
        initialValues: {
            serviceArea: { 'fields': { 'Name': "" } },
            primaryVendor: { 'fields': { 'Full Name': "" } },
            vendor: { 'fields': { 'Full Name': "" } },
            action: props.action,
            notes: ""
        },
        onSubmit: (values) => {
            console.log(values)
            submit(projectId, 'portapotty', values)
        },
    });


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md" >
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {allReady() ? `Portapotty ${props.action}` : "Loading..."}

                    </Typography>
                    {
                        allReady() ?
                            <Box component="form" noValidate sx={{ mt: 3, width: '90%' }} onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>

                                    <Grid item xs={12}>
                                        {projectInfo(project['Project Name'][0], project['Full address'], project['Precon Date'].date, project['Precon Date'].date)}
                                    </Grid>
                                    {
                                        props.action === 'request' ?
                                            <>
                                                <Grid item xs={12}>
                                                    {autocomplete(formik, "Select Area", areas, subs, setPrimaryVendor)}
                                                </Grid>

                                                <Grid item xs={12} >
                                                    <SingleSelect formik={formik} subs={subs} primaryVendor={primaryVendor}></SingleSelect>
                                                </Grid>
                                            </>
                                            : ""
                                    }
                                    <Grid item xs={12}>
                                        <TextField name="notes" onChange={formik.handleChange} value={formik.values.notes} id="outlined-basic" label="Notes" variant="outlined" fullWidth multiline />
                                    </Grid>

                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={formik.values.vendor.fields['Full Name'] === "" && props.action === 'request'}
                                >
                                    Submit
                                </Button>

                            </Box>
                            : ""
                    }

                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Portapotty;

