import axios from 'axios';

const getServiceArea = () => {
    return axios.get(`https://api.airtable.com/v0/appiScywNMqBk3x9e/Service%20Areas/`,
        {
            headers: {
                "Authorization": `Bearer patUICwnkk9q8SnFv.da5a8cd0d87db095995b1c1b3a14458bf37af437b713777457c78ea771dfd11b`
            }
        }
    )
}

const getSubs = () => {
    return axios.get(`https://api.airtable.com/v0/appiScywNMqBk3x9e/Directory/`,
        {
            params: {
                "view": "Vendors_Service_Area",
                "fields[0]": "Full Name",
                "fields[1]": "Service Area",
                "fields[2]": "Division and Trade",
            },
            headers: {
                "Authorization": `Bearer patUICwnkk9q8SnFv.da5a8cd0d87db095995b1c1b3a14458bf37af437b713777457c78ea771dfd11b`
            }
        }
    )
}


const getProject = (projectId) => {
    return axios.get(`https://api.airtable.com/v0/appucrtf5MBcFXVza/General%20Project%20Info/${projectId}`,
        {
            headers: {
                "Authorization": `Bearer patUICwnkk9q8SnFv.da5a8cd0d87db095995b1c1b3a14458bf37af437b713777457c78ea771dfd11b`
            }
        }
    )
}

const fetchAirtableData = (projectId, callback) => {
    Promise.all([getProject(projectId),getSubs(), getServiceArea() ]).then(result => {
        const project = result[0].data
        const precon_date = new Date(project.fields['Precon Date'])
        project.fields['Precon Date'] = {'date' : precon_date}
        if (project.fields['Predemo Date']) {
            const predemo_date = new Date(project.fields['Predemo Date'])
            project.fields['Predemo Date'] = {'date' : predemo_date}
        }
        
        callback(result)
    }).catch(err => console.log("Err: ", err))
}


export default fetchAirtableData;