import axios from 'axios';

const submit = (projectId, type, values) => {
    if (values.action === 'request'){
        values.action = type + 'Request'
    } else if (values.action === 'remove'){
        values.action =  type +'Remove'
    }
    values.vendor = values.vendor.fields['Full Name']
    values.id = projectId
    axios.post(
        process.env.REACT_APP_INTEGROMAT_URL,
        {
            'rawRequest': JSON.stringify(values, null, 2)
        }
    ).then(res => {
        document.location = 'https://form.basementremodeling.com/user/thank-you'
    }).catch(err => {
        alert("Something went wrong, please try again and yell at Bogdan.")
    })
}

export default submit;