import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

  const SingleSelect = (props) => {
    const {formik, subs} = props;
    const primaryVendor = formik.values.primaryVendor;
    
    const subsName = (sub) => {
      if (sub.id === primaryVendor.id) {
        return "MAIN -->> " + sub.fields['Full Name']
      } 
      return sub.fields['Full Name']
    }

    return (
        <FormControl fullWidth>
          <InputLabel id={"vendor"}>{"Vendor"}</InputLabel>
          <Select
            labelId={"vendor"}
            id={"vendor-label"}
            value={formik.values.vendor}
            label={"Vendor"}
            onChange={(e) => {
              formik.setFieldValue(`vendor`, e.target.value)
            }}
          >
            {subs.map(sub => {
              return <MenuItem key={sub.fields['Full Name']} value={sub}>{subsName(sub)}</MenuItem>
            })}
          </Select>
        </FormControl>
      )
    
  }

  export default SingleSelect;