
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



export default function projectInfo(projectName, address, precon, predemo) {
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <Card >
      <CardContent>
        <Typography sx={{ fontSize: 20 }} gutterBottom>
          {projectName}
        </Typography>
        <Typography variant="body2">
          Address: {address}<br />
          Precon: {precon.toLocaleDateString("en-US", dateOptions)}<br />
          Predemo: {predemo ? predemo.toLocaleDateString("en-US", dateOptions) : "None"}<br />
          
        </Typography>
      </CardContent>
    </Card>
  );
}