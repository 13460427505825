import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import findPrimaryVendor from './../helpers/utilities';

const autocomplete = (formik, label, options, subs, setPrimaryVendor) => {

    return (
      <Autocomplete
        style={{width: 'inherit'}}
        value={formik.values.serviceArea}
        onChange={(e, value) => { 
          const primaryVendor = findPrimaryVendor(value, subs);
          formik.setFieldValue("serviceArea", value)
          if (primaryVendor) {
            formik.setFieldValue("vendor", primaryVendor)
            formik.setFieldValue("primaryVendor", primaryVendor)
          }

        } }
        disablePortal
        id="autoComplete"
        name="autoComplete"
        getOptionLabel={option => option.fields['Name']}
        options={options}
        sx={{ width: 400 }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    )
  }

export default autocomplete